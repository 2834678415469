$(function() {
	$('.modelhouse-area').bind('change', function() {
		var linkurl = $('.modelhouse-area').val();
		$('.search-modelhouse-area').attr({href:linkurl});
	});
	$('.modelhouse-style').bind('change', function() {
		var linkurl = $('.modelhouse-style').val();
		$('.search-modelhouse-style').attr({href:linkurl});
  });
  $('.builder-area').bind('change', function() {
		var linkurl = $('.builder-area').val();
		$('.search-builder-area').attr({href:linkurl});
  });
  $('.builder-style').bind('change', function() {
		var linkurl = $('.builder-style').val();
		$('.search-builder-style').attr({href:linkurl});
  });
});
// gnav
 	
$(function(){ 
	if(window.matchMedia('(max-width:10240px)').matches) {
        $(function(){
			var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';
			$(".menu").on(_touch,function() {
				$(".menu").toggleClass("active");
				$(".gnav").toggleClass("on");
				//$(".gnav").slideToggle(200);
				return false;
			});

			$(document).on(_touch,function() {
				//$(".gnav").slideUp();
				$(".menu").removeClass("active");
			});
			$(".menu").on(_touch,function() {
				event.stopPropagation();
			});
		})
   	}
});

// $(function() {
//   const control = [ 
//     //'play-large', // 再生前に表示される再生ボタン
//     //'restart', // もう一度最初からのボタン
//     //'rewind', // seektimeで指定した秒数戻るボタン
//     //'play', // 再生、一時停止ボタン
//     //'fast-forward', // seektimeで指定した秒数進むボタン
//     //'progress', // プログレスバーの表示
//     //'current-time', // 現在時間、残り時間の表示
//     //'duration', // 動画全体の再生時間
//     //'mute', // ミュート、ミュート解除ボタン
//     //'volume', // 音量コントロール
//     //'captions', // 字幕表示ボタン
//     //'settings', // 設定メニュー（再生速度、画質選択）
//     //'pip', // ピクチャーインピクチャー
//     //'airplay', // AirPlay(MacのSafariのみ）
//     //'download', // 指定したソースのダウンロードボタン
//     //'fullscreen' // フルスクリーンボタン
//   ],
//   player = new Plyr('#player', {
//     controls: control,
//     autoplay: true,
//     fullscreen: true,
//     //loop: true
//   });
// });



//scroll
$(function(){
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top - 60;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});

//slide

// $(document).ready(function(){
//   $('.hero').slick({
//     //setting-name: setting-value
//   });
// });
  $(function(){
    $('.style__list').slick({
      // slidesToShow: 4,
      // slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      //infinite: true,
      //centerMode: false,
      variableWidth: true,
      speed: 5000,
      autoplaySpeed: 0,
      useTransform: false,
      //cssEase: linear,
      // responsive: [
      //   {
      //     breakpoint: 1240,
      //     settings: {
      //       slidesToShow: 5,
      //       // slidesToScroll: 3,
      //       // infinite: true,
      //       // dots: true
      //     }
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2
      //     }
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1
      //     }
      //   }
      //   // You can unslick at a given breakpoint now by adding:
      //   // settings: "unslick"
      //   // instead of a settings object
      // ]

    });
    $('.info__list').slick({
      // slidesToShow: 4,
      // slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      // infinite: true,
      rtl: true,
      //centerMode: true,
      variableWidth: true,
      speed: 5000,
      autoplaySpeed: 0,
      useTransform: false,
      // responsive: [
      //   {
      //     breakpoint: 1240,
      //     settings: {
      //       slidesToShow: 3,
      //       // slidesToScroll: 3,
      //       // infinite: true,
      //       // dots: true
      //     }
      //   },
      //   {
      //     breakpoint: 600,
      //     settings: {
      //       slidesToShow: 2,
      //       slidesToScroll: 2
      //     }
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       slidesToShow: 1,
      //       slidesToScroll: 1
      //     }
      //   }
      //   // You can unslick at a given breakpoint now by adding:
      //   // settings: "unslick"
      //   // instead of a settings object
      // ]
    });
    $('#sbi_images').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 4,
            // slidesToScroll: 3,
            // infinite: true,
            // dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });
    $('.slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      infinite: true,
      dots: true,
    });
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav',
      // adaptiveHeight: true,
      // variableWidth: true
    });
    $('.slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      // adaptiveHeight: true,
      // variableWidth: true
    });
  });

// $(function(){
//   $('.slider-for').slick({
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     fade: true,
//     asNavFor: '.slider-nav',
//     // adaptiveHeight: true,
//     // variableWidth: true
//   });
//   $('.slider-nav').slick({
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     asNavFor: '.slider-for',
//     dots: true,
//     centerMode: true,
//     focusOnSelect: true,
//     // adaptiveHeight: true,
//     // variableWidth: true
//   });
// });

// $(function(){
//   var $window = $(window);
//   var $body = $('body');
//   $('.home .hero').slick({
//     fade: true,
//     arrows: false,
//     autoplay:true,
//     autoplaySpeed:4000,
//     speed: 1200,
//     pauseOnFocus: false,
//     pauseOnHover: false,
//     //easing: ease
//   });
//     var $container = $(".home .hero");
//     var $slider = $(".home .hero");
//     var $slide = $(".home .hero div");
//     function Resize(){
//     var winW = $window.width();
//     var winH = $window.height();
//       $container.width(winW);
//       $container.height(winH);
//       $slide.width(winW);
//       $slide.height(winH);
//       $slider.width(winW);
//       $slider.height(winH);
//     }
//     $window.on("resize",Resize);
//     Resize();
// });



//sticky header1
$(function() {
  var $win = $(window),
      $main = $('main'),
      $nav = $('.home #header'),
      $wrapper = $('.home .wrapper'),
      navHeight = $nav.outerHeight(),
      navPos = $nav.offset().top,
      fixedClass = 'fixed';

  $win.on('load scroll', function() {
    var value = $(this).scrollTop();
    if ( value > navPos ) {
      $nav.addClass(fixedClass);
      $wrapper.addClass('wrapper-fix');
      //$main.css('margin-top', '400px');
      // $('.home #reservation-nav').slideDown(200);
      // $('.page .tinys').addClass('active');
      // $('.page .hero').css('margin-top', '128px');
      // $('.news .contents').css('margin-top', '128px');
    } else {
      $nav.removeClass(fixedClass);
      $wrapper.removeClass('wrapper-fix');
      // $main.css('margin-top', '0');
      // $('.home #reservation-nav').slideUp(200);
      // $('.page .tinys').removeClass('active');
      // $('.page .hero').css('margin-top', '0');
      // $('.news .contents').css('margin-top', '0');
    }
  });
});


//animation
$(function(){
  $('.home .hero').on('animationstart',function (){
     $(this).css('opacity', '1');
  });
});
$(function(){
  //$('.anime').css("opacity","0");
  $(window).scroll(function (){
  	if ($(this).scrollTop() > 300){  
    	$('.pagetop').addClass("visible");
    	//$('#reservation-nav').slideDown("fast");
  	}
	else{
		$('.pagetop').removeClass("visible");
	//$('#reservation-nav').slideUp("fast");
	}
    $(".anime").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move');
      } 
      // else {
      //   $(this).removeClass('move');
      //   // $(this).css({ 
      //   //   "font-size": "20px",
      //   //   "padding": "20px"
      //   // });
      // }
    });
    //Right
    $(".anime-r").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-r');
      }
    });
    //left
    $(".anime-l").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-l');
      }
    });

    //dilay
    $(".anime-d").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-d');
      }
    });
    //bottom
    $(".anime-b").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-b');
      }
    });

  });
});


jQuery(document).ready(function($) {
  $('a[data-rel^=lightcase]').lightcase({
  transition: 'fade',
  showSequenceInfo: false,
  showCaption: false
  }
    );
});